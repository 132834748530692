import React, { forwardRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { Stack, chakra } from '@chakra-ui/react'

const Form = forwardRef(
  (
    {
      form,
      onSubmit,
      onError = () => null,
      children,
      layout = 'vertical',
      ...rest
    },
    ref
  ) => {
    const { handleSubmit } = form;

    const formProps = {};
    if(onSubmit && typeof onSubmit === 'function') {
      formProps.onSubmit = handleSubmit(onSubmit, onError)
    }

    return (
      <FormProvider {...form} layout={layout}>
        <chakra.form
          w="100%"
          ref={ref}
          {...formProps}
          css={{
            'button': {
              display: 'inline-block'
            }
          }}
        >
          <Stack
            w="100%"
            direction={layout === 'vertical' ? 'column' : {base: 'column', lg: 'row'}}
            spacing={3}
            align={layout === 'vertical' ? 'center' : {base: 'center', lg: 'flex-end'}}
            pb={{ base: 0, lg: layout === 'vertical' ? 0 : '22px' }}
            {...rest}
          >
            {children}
          </Stack>
        </chakra.form>
      </FormProvider>
    );
  }
);

export default Form;
