import { defineMessages } from 'react-intl';

export const scope = 'components.forms.FormItem';

export default defineMessages({
  requiredNoLabel: {
    id: `${scope}.requiredNoLabel`,
    defaultMessage: 'This field is required',
  },
  required: {
    id: `${scope}.required`,
    defaultMessage: '{field} is required',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Please enter a valid email',
  },
  url: {
    id: `${scope}.url`,
    defaultMessage: 'Please enter a valid url',
  },
  minLength: {
    id: `${scope}.minLength`,
    defaultMessage: 'Please enter more than {ruleValue} characters'
  },
  min: {
    id: `${scope}.min`,
    defaultMessage: 'Please enter a value more than {ruleValue}'
  },
  max: {
    id: `${scope}.max`,
    defaultMessage: 'Please enter a value less than {ruleValue}'
  },
  validate: {
    id: `${scope}.validate`,
    defaultMessage: 'Please enter a valid value'
  }
});
