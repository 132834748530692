export const selectDataConfigWithPropertyConfig = ({dataConfig, isPetFriendly, maxPets, maxOccupancy}) => {
  if(!isPetFriendly && dataConfig.pets) {
    dataConfig.pets.active = false;
  }

  if(isPetFriendly && maxPets > 0 && dataConfig.pets) {
    dataConfig.pets.max = maxPets;
  }

  if(dataConfig.guests && maxOccupancy) {
    dataConfig.guests.max = maxOccupancy;
  }

  if(dataConfig.infants && maxOccupancy) {
    dataConfig.infants.max = maxOccupancy * 2; // Assumes there could be 2 infants for every adult?
  }

  return dataConfig;
};
