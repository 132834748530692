import { isObject } from '@rentivo/gatsby-core/src/utils/objects';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import messages from '@rentivo/gatsby-core/src/components/forms/FormItem/messages';

export const useBasicRules = (rules = {}, field = 'field') => {

  const { formatMessage } = useIntl();

  return useMemo(() => {
    const initRules = {};
    for (const [ruleKey, ruleValue] of Object.entries(rules)) {
      switch (ruleKey) {
        case 'email':
          initRules['pattern'] = {
            message: formatMessage(messages.email, { field }),
            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          };
          break;

        case 'url':
          initRules['pattern'] = {
            message: formatMessage(messages.url, { field }),
            value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
          };
          break;

        case 'required':
          initRules[ruleKey] = {
            value: true,
            message: field !== 'field' && field ? formatMessage(messages.required, { field }) : formatMessage(messages.requiredNoLabel)
          };
          break;

        case 'minLength':
          initRules[ruleKey] = {
            value: ruleValue,
            message: formatMessage(messages.minLength, { field, ruleValue })
          };
          break;

        case 'min':
          initRules[ruleKey] = {
            value: ruleValue,
            message: formatMessage(messages.min, { field, ruleValue: ruleValue - 1})
          };
          break;

        case 'max':
          initRules[ruleKey] = {
            value: ruleValue,
            message: formatMessage(messages.max, { field, ruleValue: ruleValue + 1})
          };
          break;

        case 'validate':
          initRules[ruleKey] = ruleValue;
          break;

        default:
          initRules[ruleKey] = ruleValue;
      }

      if(isObject(ruleValue)) {
        initRules[ruleKey] = { ...initRules[ruleKey],  ...ruleValue };
      }
    }

    return initRules;

  }, [rules, field]);
};
