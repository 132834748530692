import React from 'react';
import { createSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  selectPropertyPricingDataConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  makeSelectPropertyListingPetsObject,
  selectPropertyListingMaxOccupancy,
  selectPropertyUnitAvailability
} from '@rentivo/gatsby-core/src/selectors/property';
import {
  selectPricingDatesPopoverOpen,
  selectPricingEndDate,
  selectPricingGuestsObject,
  selectPricingGuestsObjectWithPetsConfig, selectPricingGuestsPopoverOpen, selectPricingStartDate
} from '@rentivo/gatsby-core/src/containers/PricingProvider/selectors';
import { selectDataConfigWithPropertyConfig } from '@rentivo/gatsby-core/src/containers/PricingProvider/components/GuestsPicker/selectors';
import {
  setDatesPopoverOpen,
  setGuestsPopoverOpen,
  startUpdateDates,
  startUpdateGuests
} from '@rentivo/gatsby-core/src/containers/PricingProvider/actions';
import { withCommerce } from '@rentivo/gatsby-core/src/context/CommerceContext';
import isEmpty from 'lodash/isEmpty';

const propertySelectors = createSelector(
  makeSelectPropertyListingPetsObject,
  selectPropertyListingMaxOccupancy,
  selectPropertyUnitAvailability,
  ({isPetFriendly, maxPets}, maxOccupancy, unitAvailability) => ({
    isPetFriendly,
    maxPets,
    maxOccupancy,
    unitAvailability
  })
);

const withPricingInputs = WrappedComponent => {

  const withPricingInputsComponent = (props) => {
    return <WrappedComponent {...props} />;
  };

  const mapStateToProps = (state, {isPetFriendly, maxPets, maxOccupancy, commerce}) => createSelector(
    selectPricingStartDate,
    selectPricingEndDate,
    selectPricingGuestsObject,
    selectPropertyPricingDataConfig,
    selectPricingDatesPopoverOpen,
    selectPricingGuestsPopoverOpen,
    (startDate, endDate, guestsObj, dataConfig, datesPopoverOpen, guestsPopoverOpen) => {
      guestsObj = selectPricingGuestsObjectWithPetsConfig(guestsObj, isPetFriendly);
      const { guests = 0 } = guestsObj;
      dataConfig = selectDataConfigWithPropertyConfig({dataConfig, isPetFriendly, maxPets, maxOccupancy});
      return {
        startDate,
        endDate,
        guestsObj,
        areGuestsSelected: (guests > 0),
        areDatesSelected: (startDate !== null && endDate !== null),
        dataConfig,
        datesPopoverOpen,
        guestsPopoverOpen,
        inCheckout: !isEmpty(commerce)
      };
    }
  );

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { inCheckout } = stateProps;

    return {
      ...stateProps,
      ...ownProps,
      setGuests: (guests) => dispatch(startUpdateGuests(guests, inCheckout)),
      setDates: (dates) => dispatch(startUpdateDates(dates, inCheckout)),
      setDatesPopoverOpen: (open) => dispatch(setDatesPopoverOpen(open)),
      setGuestsPopoverOpen: (open) => dispatch(setGuestsPopoverOpen(open))
    }
  };

  const enhance = compose(
    withProperty(propertySelectors),
    withCommerce(),
    connect(mapStateToProps, null, mergeProps),
  );

  return enhance(withPricingInputsComponent);

};

export default withPricingInputs;
