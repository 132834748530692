import React, { forwardRef, useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';

import { fieldErrorMessage } from '@rentivo/gatsby-core/src/components/forms/FormItem/utils';
import { useBasicRules } from '@rentivo/gatsby-core/src/components/forms/FormItem/hooks';

const FormItem = forwardRef(
  (
    {
      name,
      rules,
      render,
      defaultValue,
      label,
      info,
      isCheckbox = false,
      isInvalid = false,
      isDisabled = false,
      isLoading = false,
      isRequired = false,
      isReadOnly = false,
      orientation,
      ...rest
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const { control, errors, layout = 'vertical' } = useFormContext();
    orientation = orientation ? orientation : layout;

    const error = fieldErrorMessage(errors, name);
    const basicRules = useBasicRules(rules, label);

    return (
      <FormControl
        id={name}
        isInvalid={error || isInvalid}
        isDisabled={isDisabled}
        orientation={orientation}
        isReadOnly={isReadOnly}
        isRequired={isRequired}
        isLoading={isLoading}
        ref={ref}
        {...rest}
      >
        {label && (<FormLabel htmlFor={name} mb={1}>{label}</FormLabel>)}
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={basicRules}
          render={({ onChange, value}) => {
            const commonProps = { name, ref: inputRef };
            const typeProps = (isCheckbox) ? { onChange: (e) => onChange(e.target.checked), value: 1, isChecked: value } : { onChange, value };
            return render({...commonProps, ...typeProps});
          }}
        />
        {info && (<FormHelperText>{info}</FormHelperText>)}
        {error && (<FormErrorMessage position={{ base: 'relative', lg: layout === 'vertical' ? 'relative' : 'absolute' }}>{error}</FormErrorMessage>)}
      </FormControl>
    );
  }
);

// onFocus: inputRef && inputRef.current && inputRef.current.focus()

export default FormItem;
